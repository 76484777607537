import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { mobileGuard } from '@shared/mobile-guard.service';
import { authGuard } from '@shared/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: async () => (await import('./features/auth/auth.module')).AuthModule
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'registration',
        loadChildren: async () => (await import('./features/registration/registration.module')).RegistrationModule,
        canActivate: [authGuard, mobileGuard]
      },
      { path: '**', component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
