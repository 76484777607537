import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LayoutService } from './layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent {
  isLoaderVisible = this.layoutService.isLoading;

  constructor(private layoutService: LayoutService) {}
}
