import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

export const mobileGuard = (): boolean | UrlTree => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (screenWidth < 900) {
    const router = inject(Router);
    return router.parseUrl('auth/desktop');
  }
  return true;
};
