import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { CheckboxModule } from 'primeng/checkbox';
import { TagModule } from 'primeng/tag';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MessagesModule } from 'primeng/messages';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ToastModule } from 'primeng/toast';
import { HttpClientModule } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [NotFoundComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    ButtonModule,
    CheckboxModule,
    ConfirmPopupModule,
    InputTextModule,
    MessagesModule,
    ProgressSpinnerModule,
    TagModule,
    ToastModule,
    RippleModule
  ],
  exports: [
    ReactiveFormsModule,
    HttpClientModule,
    ButtonModule,
    CheckboxModule,
    ConfirmPopupModule,
    InputTextModule,
    MessagesModule,
    ProgressSpinnerModule,
    TagModule,
    ToastModule,
    RippleModule
  ],
  providers: [ConfirmationService, MessageService]
})
export class SharedModule {}
